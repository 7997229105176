import { Component, OnInit } from '@angular/core';
import { ServerService } from '../server.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-found',
  templateUrl: './found.component.html',
  styleUrls: ['./found.component.scss']
})
export class FoundComponent implements OnInit {

  public places: any;

  constructor(private serverService: ServerService) { }

  public getPlaces() {
    this.serverService.getPlaces().then((resp) => {
      if(_.isNil(resp)){
        this.places = 'non';
      }else {
        this.places = resp;
      }
    });
  }

  public getUIPlaces() {
    return JSON.stringify(this.places);
  }

  ngOnInit(): void {
    this.getPlaces();
  }

}
