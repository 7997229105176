import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  private urlServer = environment.apiUrl;
  private token = environment.token;

  constructor(private http: HttpClient) { }

  getFolders(path: string, order: string): any {
    return this.http.get(this.urlServer + '/getFolders.php?token='+ this.token +'&order=' + order + '&path=' + path).toPromise();
  }

  getCentres(localite: string): any {
    if(_.isNil(localite)) {
      return this.http.get(this.urlServer + '/getCentres.php?token='+ this.token).toPromise();
    } else {
      return this.http.get(this.urlServer + '/getCentres.php?token='+ this.token +'&localite=' + localite).toPromise();
    }
  }

  getPlaces() : any  {
    return this.http.get(this.urlServer + '/getPlaces.php?token='+ this.token).toPromise();
  }
}
