import { Component, OnInit,ViewChild } from '@angular/core';
import { ServerService } from '../server.service';
import * as _ from 'lodash';
import { Centre } from '../models/Centre';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import { DomSanitizer } from '@angular/platform-browser';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-stats',
  templateUrl: 'stats.component.html',
  styleUrls: ['stats.component.css']
})
export class StatsComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public centres: Centre[] = [];
  public places: any;
  public dataSource : any;
  displayedColumns: string[] = ['id', 'date', 'departement', 'localite', 'nb', 'message'];
  constructor(private serverService: ServerService, private sanitizer:DomSanitizer) {}

  scrollToDownload(element: any) {
    element.scrollIntoView({ behavior: 'smooth' });
  }


  ngOnInit() {
    this.getCentres();
    this.getPlaces();
  }

  public showLocalite(localite){
    this.serverService.getCentres(localite).then((resp) => {
      const details = [];
      _.each(resp, (el) => {
        details.push(new Centre(el));
      });
      Swal.fire({ title: details[0].localite, html: this.htmlFormatCentre(details) });
    });
  }

  public htmlFormatCentre(centres) {
    let hasfound = false;
    let html = '';
    centres.forEach(element => {
      if(element.message === 'Plus de place disponible pour le moment') {
        html += '<li style="color:black;"><small>' + element.date + ' : <span style="color:red;">' + element.message + '</span></small></li>';
      }else {
        hasfound = true;
        html += '<li style="color:black;">' + element.date + ' : <span style="color:green;">' + element.message + '</span></li>';
      }
    });
    if(!hasfound) {
      return '<span style="color:red;">Pas de place actuellement...</span>';
    }
    return '<ul style="text-align:left;">' + html +'</ul>';
  }

  // public isFound(localite) {
  //   this.serverService.getCentres(localite).then((resp) => {
  //     const centres = [];
  //     _.each(resp, (el) => {
  //       centres.push(new Centre(el));
  //     });
  //     let hasfound = false;
  //     centres.forEach(element => {
  //       if(element.message !== 'Plus de place disponible pour le moment') {
  //         hasfound = true;
  //       }
  //     });
  //     if(hasfound) {
  //       return this.sanitizer.bypassSecurityTrustHtml('<span style="color:red">Rien de trouvé</ul>');
  //     }
  //     return this.sanitizer.bypassSecurityTrustHtml('<span style="color:green">trouvé !!</ul>');
  //   });
  // }

  public getPlaces() {
    this.serverService.getPlaces().then((resp) => {
      if(_.isNil(resp)){
        this.places = 'Pas de place';
      }else {
        this.places = resp;
      }
    });
  }

  public getUIPlaces() {
    return JSON.stringify(this.places);
  }

  public getCentres() {
    this.serverService.getCentres(null).then((resp) => {
      _.each(resp, (el) => {
        this.centres.push(new Centre(el));
      });
      this.dataSource = new MatTableDataSource(this.centres);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }
}
