import * as _ from 'lodash';

export class ServerFile {

    public isDir: boolean;
    public path: string;
    public name: string;
    public type: string;
    public baseDir: string;

    constructor(obj: any) {

        if(!_.isNil(obj)) {
            this.isDir = obj.isDir;
            this.path = _.replace(obj.path,'//', '/');
            this.name = obj.name.toLowerCase();
            this.baseDir = obj.baseDir;
            let lowerName = this.name.toLowerCase();
            if(lowerName.endsWith('.mp4') || lowerName.endsWith('.avi') || lowerName.endsWith('.mpeg')) {
                this.type = 'video';
            } else if(lowerName.endsWith('.jpg') || lowerName.endsWith('.png') || lowerName.endsWith('.jpeg')) {
                this.type = 'image';
            } else {
                this.type = 'other';
            }
        }
    }
}