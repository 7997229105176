import * as _ from 'lodash';

export class Centre {
    public id: number;
    public date: Date;
    public departement: string;
    public localite: string;
    public nb: string;
    public message: string;

    constructor(obj: any) {

        if(!_.isNil(obj)) {
            this.id = obj.id;
            this.date = new Date(obj.date);
            this.departement = obj.departement;
            this.localite = obj.localite;
            this.nb = obj.nb;
            this.message = obj.message;
        }
    }
}