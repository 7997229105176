import { Component, OnInit } from "@angular/core";

import Glide from "@glidejs/glide";
import { ServerService } from '../server.service';
import * as _ from 'lodash';
import { ServerFile } from '../models/ServerFile';
import { environment } from 'src/environments/environment';


@Component({
  selector: "app-presentation",
  templateUrl: "presentation.component.html"
})
export class PresentationComponent implements OnInit {

  public fichiers: ServerFile[] = [];
  public dossiers: ServerFile[] = [];
  private modelsUrl = environment.modelsUrl;
  public currentFolder = '/';
  public previousFolder = '/';
  public visitedFolder = [];
  public order = 'ASC';
  public places: any;

  constructor(private serverService: ServerService) {}

  getBackFolders() {

  }

  public getPlaces() {
    this.serverService.getPlaces().then((resp) => {
      if(_.isNil(resp)){
        this.places = 'Pas de place';
      }else {
        this.places = resp;
      }
    });
  }

  public getUIPlaces() {
    return JSON.stringify(this.places);
  }

  scrollToDownload(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
  }
  ngOnInit() {
    this.currentFolder = '/';
    this.previousFolder = '/';
    this.getFolders(this.currentFolder);
    this.getPlaces();
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('presentation-page');
  }

  public getPreviousFolders(path) {
    this.getFolders(this.previousFolder);
  }

  public getVisitedClass(path): string {
    if(_.indexOf(this.visitedFolder, path) > -1) {
      return 'btn-warning';
    } else {
      return 'btn-info';
    }
  }

  public changeOrder() {
    if(this.order === 'ASC'){
      this.order = 'DESC';
    } else {
      this.order = 'ASC';
    }
    this.getFolders(this.currentFolder);
  }

  public getFolders(path) {
    if(_.indexOf(this.visitedFolder, path) === -1) {
      this.visitedFolder.push(path);
    }
    this.dossiers = [];
    this.fichiers = [];
    this.currentFolder = path;
    path = _.replace(path,'//', '/')
    let arr = _.split(path, '/');
    this.currentFolder = path;
    this.previousFolder = '';
    for (let i = 0; i < arr.length-1; i++) {
      this.previousFolder += '/' + arr[i];
    }
    if(this.previousFolder === '') {
      this.previousFolder = '/';
    }
    this.serverService.getFolders(path, this.order).then((resp) => {
      _.each(resp, (el) => {
        if(el.isDir === true) {
          let sf = new ServerFile(el);
          this.dossiers.push(sf);
        }else {
          let sf = new ServerFile(el);
          this.fichiers.push(sf);
        }
      });
    });
  } 
}
